module.exports = {
  title: {
    left: 25,
    text: '系统访问数据统计'
  },
  legend: {
    itemHeight: 16,
    itemWidth: 16,
    left: 25,
    top: 40,
    data: [
      {
        name: '访问量',
        icon: 'roundRect',
        itemStyle: {
          color: '#1e5eff'
        }
      },
      {
        name: '活跃度',
        icon: 'roundRect',
        itemStyle: {
          color: '#99e69b'
        }
      }
    ]
  },
  dataZoom: [
    {
      type: 'slider',
      show: true,
      maxValueSpan: 30,
      handleSize: '100%',
      bottom: 8,
      height: 20
    },
    {
      type: 'inside'
    }
  ],
  grid: {
    top: 120,
    bottom: 60,
    left: 60,
    right: 60
  },
  dataset: {
    dimensions: ['date', 'visits', 'active'],
    source: []
  },
  tooltip: {
    trigger: 'axis'
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      show: true,
      interval: 0,
      formatter: function (params) {
        var newParamsName = '';
        var paramsNameNumber = params.length;
        var provideNumber = 5;
        var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
        if (paramsNameNumber > provideNumber) {
          for (var p = 0; p < rowNumber; p++) {
            var tempStr = '';
            var start = p * provideNumber;
            var end = start + provideNumber;
            if (p === rowNumber - 1) {
              tempStr = params.substring(start, paramsNameNumber);
            } else {
              tempStr = params.substring(start, end) + '\n';
            }
            newParamsName += tempStr;
          }
        } else {
          newParamsName = params;
        }
        return newParamsName;
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed'
      }
    }
  },
  yAxis: [
    {
      name: '访问量(次)',
      nameGap: 20,
      splitNumber: 6,
      min: 0,
      alignTicks: true
    },
    {
      name: '活跃度(人)',
      nameGap: 20,
      min: 0,
      alignTicks: true,
      splitStyle: {
        lineStyle: {
          type: 'dashed'
        }
      }
    }
  ],
  series: [
    {
      name: '访问量',
      type: 'bar',
      yAxisIndex: 0,
      itemStyle: {
        color: '#1e5eff'
      }
    },
    {
      name: '活跃度',
      type: 'bar',
      yAxisIndex: 1,
      itemStyle: {
        color: '#99e69b'
      }
    }
  ]
};
